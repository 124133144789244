import React from 'react'
// Customizable Area Start
import AppHeaderController, { configJSON } from './HeaderController.web';
import { IMenuItem } from './Types';
import './assets/Header.css';
import {
  OutlinedInput,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Popper,
  Button,
  Popover,
  Box
} from '@material-ui/core';
import {
  Search,
  Menu as MenuIcon,
  ChevronRight,
  Close,
  History,
} from '@material-ui/icons';
import { withStyles, styled } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import Skeleton from '@material-ui/lab/Skeleton';
// Customizable Area End

export class AppHeader extends AppHeaderController {
  // Customizable Area Start
  designDetails = JSON.parse(localStorage.getItem('designDetails') 
    || '{ "header": { "storeLogo": "", "showStoreName": false, "headerItems": [{ "itemUrl": "", "categoryName": "" }] } }');
  store = JSON.parse(localStorage.getItem('customerServices') || '{ "storeName": "" }');
  render() {    
    const { classes } = this.props;
    const {
      isLoading,
      anchorEl,
      isMobile,
      isMenuOpen,
      searchValue,
      menuItems,
      selectedMenuItem,
      searchItems,
      historySearchItems,
      isSearchLoading,
      isSearchOpen,
      isSearchPage,
    } = this.state;

    const renderSearchItem = (item: any, index: number, isFromHistory: boolean = false) => (
      <ListItem 
        button
        key={index}
        className={isMobile ? classes.listItem : classes.searchListItem}
        onClick={() => this.handleRedirect(isFromHistory ? `/catalogue?search=${item}` : item.url)}
      >
        {isFromHistory
          ? <History className={classes.searchIcon} />
          : item.image
            ? <img src={item.image} alt={item.name} className="AppHeader__search__itemImage" />
            : <Search className={classes.searchIcon} />
        }
        <ListItemText 
          primary={<span 
            dangerouslySetInnerHTML={{ __html: (isFromHistory ? item : item.name)
              .replace(new RegExp(searchValue, 'gi'), (match: any) => `<b>${match}</b>`) }} 
          />} 
        />
      </ListItem>
    )

    const renderSearchItems = (
      <>
        {isSearchLoading
          ? <CircularProgress className={classes.searchLoader} />
          : searchItems.map((item, index) => (
            renderSearchItem(item, index)
        ))}
        {historySearchItems.length > 0 && (
          historySearchItems.filter((item: any) => item.includes(searchValue)).map((item: any, index: number) => (
            renderSearchItem(item, index, true)
          ))
        )}
      </>
    )

    const renderMobile = (
      <div className="AppHeader AppHeader--mobile">
        <div className='AppHeader__menuMobile'>
          <MenuIcon 
            className={classes.menuIcon}
            onClick={() => this.handleMenuOpen(false)} 
          />
          <Drawer
            anchor="left"
            open={isMenuOpen}
            onClose={this.handleMenuClose}
            classes={{ paper: classes.drawerPaper }}
          >
            <div className='AppHeader__menuMobile__drawerTop'>
              <Close className={classes.closeButton} onClick={this.handleMenuClose} />
              <p className="AppHeader__menuMobile__title">
                {isSearchPage ? configJSON.searchPlaceholder.toUpperCase() : configJSON.menu.toUpperCase()}
              </p>
              {isSearchPage && <OutlinedInput
                onFocus={() => this.handleSearchVisibility('focus')}
                onBlur={() => this.handleSearchVisibility('blur')}
                value={searchValue}
                onChange={(e) => this.setSearchValue(e.target.value)}
                onKeyDown={(e) => this.handleSearchKeyDown(e.key)}
                placeholder={configJSON.searchPlaceholder}
                classes={{
                  root: [classes.search, classes.searchMobile].join(' '),
                  input: classes.input,
                }}
                startAdornment={<Search className={classes.searchIcon} />}
                endAdornment={<Close 
                  className={classes.closeIcon} 
                  onClick={() => this.setSearchValue('')}
                />}
              />}
            </div>
            {selectedMenuItem &&
              <span className='AppHeader__menuMobile__activeTitle'>
                {selectedMenuItem.categoryName.toUpperCase()}
              </span>
            }
            <List className={classes.list}>
              {/* {isLoading && <CircularProgress className='AppHeader__menuMobile_circularProgress'/>} */}
              {(isSearchOpen && searchItems.length > 0) ? renderSearchItems : !isSearchPage &&
              (selectedMenuItem?.categoryItems ?? [...this.designDetails.header.headerItems, ...menuItems]).map((item: any, index: number) => (
               (item && <ListItem 
                  button
                  key={index}
                  className={classes.listItem}
                  onClick={(e) => {
                    if (item.itemUrl || selectedMenuItem) {
                      this.handleRedirect(item.itemUrl);
                    } else {
                      this.handleClick(e, item);
                    }
                  }}
                >
                  <ListItemText 
                    primary={item.categoryName ?? item.itemName} 
                    disableTypography 
                    className={item.categoryName ? classes.listItemTextBold : classes.listItemText}
                  />
                  {!selectedMenuItem && <ChevronRight style={{ color: 'var(--design-secondary-color)' }} />}
                </ListItem>)
              ))}
            </List>
          </Drawer>
        </div>
        <div className="AppHeader__logo" onClick={() => this.handleRedirect('/')}>
        {
            isLoading ? 
            <Skeleton variant="circle" width={50} height={50} />
             :
            <img src={this.designDetails.header.storeLogo} alt="logo" />
          }
        </div>
        {this.designDetails.header.showStoreName && 
        (!isLoading ?
          <span className='storeName'>{this.store.storeName}</span>:
            <Skeleton variant="text" width={130}/> 
          )}
        <div className="AppHeader__profile">
          <Search onClick={() => this.handleMenuOpen(true)} style={{ color: 'var(--design-secondary-color)' }} />
          <div className='AppHeader__shoppingCartIcon' onClick={() => this.handleRedirect('/shopping-cart')}>
            <Badge badgeContent={this.state.countValue} classes={{ badge: 'AppHeader__shoppingCartBadge' }} >
            {shoppingCartIconSvg}
          </Badge>
          </div>
        </div>
      </div>
    )

    const renderWeb = (
      <div className="AppHeader">
        <div className="AppHeader__logo" onClick={() => this.handleRedirect('/')}>
          {
            isLoading ? 
            <Skeleton variant="circle" width={50} height={50} />
             :
            <img src={this.designDetails.header.storeLogo} alt="logo" />
          }
        </div>
        <div className="AppHeader__menu">
          {this.designDetails.header.showStoreName &&
          (
            !isLoading ?
           <span className='storeName'>{this.store.storeName}</span> :
          <Skeleton variant="text" width={130}/> 
          )}
          {/* {isLoading && <CircularProgress size={20} />} */}
          {this.designDetails.header.headerItems.map((item: { itemUrl: string, categoryName: string }, index: number) => (
           (item && 
              <a 
                key={index + item.categoryName}
                onClick={(e) => this.handleRedirect(item.itemUrl)}
                onMouseEnter={(e) => {
                  this.handleClick(e, item);
                  this.handleMoreButtonClose();
                }}
              >{item.categoryName}</a>)
          ))}
          <CustomPopOver
            transitionDuration={0}
            style={{ maxWidth: '300px', minHeight: '200px', height: 'auto' }}
            PaperProps={{ onMouseLeave: this.handleClose }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {menuItems.find((menu: IMenuItem) => menu.categoryName === selectedMenuItem?.categoryName)?.categoryItems?.map((subItem: any, index: number) => (
              <CustomMenuItemSubLink key={index + subItem.itemName} onClick={() => this.handleRedirect(subItem.itemUrl)}>{subItem.itemName}</CustomMenuItemSubLink>
            ))}
          </CustomPopOver>
          <Button
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"

            className={this.props.classes.moreButton}
            onMouseEnter={(e) => {
              this.handleMoreButtonOpen(e);
              this.handleClose();
            }}
          >
            {configJSON.moreButton}
          </Button>
          <CustomPopOver
            transitionDuration={0}
            PaperProps={{
              onMouseLeave: this.handleMoreButtonClose,
            }}
            id="long-menu"
            open={Boolean(this.state.moreButtonAnchor)}
            anchorEl={this.state.moreButtonAnchor}
            onClose={this.handleMoreButtonClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            { this.state.menuItems.length === 0 && 
            <Box className={this.props.classes.skeltonContainer}>
            {Array.from({length:configJSON.skeltonItemsLength}).map(
              (_,index) => 
            <Skeleton key={index} variant='text'/>
            )}
            </Box>
            }
            {this.state.menuItems.length > 0 
            && this.state.menuItems.map((item) => {
              if(!item) return null;
              const menu = (
                <CustomMenuItemLink
                  onClick={() => {
                    if (item?.itemUrl || item?.webItemUrl) {
                      this.handleRedirect(item.itemUrl as string || item.webItemUrl as string);
                    }
                  }}
                >
                  {item.categoryName}
                </CustomMenuItemLink>
              );

              if (item?.categoryItems?.length === 0) {
                return menu;
              }

              const subMenu = item?.categoryItems?.map((subItem) => (
                <CustomMenuItemSubLink
                  onClick={() => {
                    if (subItem?.itemUrl) {
                      this.handleRedirect(subItem.itemUrl);
                    }
                  }}
                  key={subItem.itemName}>{subItem.itemName}
                </CustomMenuItemSubLink>
              ))
              return (
                <>
                  {menu}
                  {subMenu}
                </>
              )

            })}
          </CustomPopOver>
        </div>
        <div className="AppHeader__search" id="AppHeader__SearchContainer">
          <OutlinedInput
            onFocus={() => this.handleSearchVisibility('focus')}
            onBlur={() => this.handleSearchVisibility('blur')}
            value={searchValue}
            onChange={(e) => this.setSearchValue(e.target.value)}
            onKeyDown={(e) => this.handleSearchKeyDown(e.key)}
            placeholder={configJSON.searchPlaceholder}
            classes={{
              root: isSearchOpen ? [classes.search, classes.searchOpen].join(' ') : classes.search,
              input: classes.input,
            }}
            startAdornment={<Search className={classes.searchIcon} />}
          />
        </div>
        <Popper
          open={isSearchOpen && searchItems.length > 0}
          anchorEl={document.getElementById('AppHeader__SearchContainer')}
          className={classes.searchPopper}
          style={{ width: document.getElementById('AppHeader__SearchContainer')?.clientWidth }}
          placement="bottom-start"
        >
          <List className={classes.searchList}>
            {renderSearchItems}
          </List>
        </Popper>
        <div className="AppHeader__shoppingCartContainer">
          <div className="AppHeader__shoppingCartIcon" onClick={() => this.handleRedirect('/shopping-cart')} >
          <Badge badgeContent={this.state.countValue} classes={{ badge: 'AppHeader__shoppingCartBadge' }} >
            {shoppingCartIconSvg}
          </Badge>
        </div>
        </div>
      </div>
    )

    return isMobile ? renderMobile : renderWeb;
  }
  // Customizable Area End
}

// Customizable Area Start
const CustomPopOver = styled(Popover)({
  width:"664px",
  height:"350px",
  marginTop:"-1.5rem",
  marginRight:"-5rem",
  inset: '72px !important',
  '& .MuiPopover-paper': {
    top: '12px !important',
    paddingLeft:"1rem",
    paddingRight:"1rem",
    paddingTop:"1rem",
    paddingBottom:".5rem",
    display:"flex",
    flexDirection:"column",
    flexWrap:"wrap",
    height:"inherit",
    width:"inherit",
    border:"1px solid #E2E8F0",
    borderRadius:"8px",
    overflowX: 'auto' as 'auto',
    '&::-webkit-scrollbar': {
      height: 4,
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: '#CBD5E1',
      borderRadius: 2,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--design-primary-color)',
      borderRadius: 2,
    },
  },
  '& div:first-child': {
    inset: 'unset !important',
  },
})

const CustomMenuItemLink = styled(MenuItem)({
  color:"var(--design-primary-color)",
  fontSize:"14px",
  fontWeight:600,
  padding:"4px 8px",
  fontFamily: 'var(--design-body-text-font)',
  lineHeight: '26px',
  textAlign: 'left',
})

const CustomMenuItemSubLink = styled(MenuItem)({
  fontSize:"14px",
  padding:"4px 8px",
  color:"var(--design-secondary-color)",
  fontWeight:400,
  fontFamily: 'var(--design-body-text-font)',
  lineHeight: '22px',

})
const styles = () => ({
  skeltonContainer:{
    display:'flex',
    flexDirection:"column" as "column",
    flexWrap:"wrap" as "wrap",
    height:"100%",
    width:"100%",
    justifyContent:"space-between",
  },
  moreButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    cursor: 'pointer',
    color: "var(--design-secondary-color)",
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '18px',
    textDecoration: 'none',
    textTransform: 'none' as "none",
  },
  stylePopover:{
    padding:"1rem",
    marginTop:"1rem",
    "& .MuiPopover-root":{
      padding:"2rem"
    },
  },
  search: {
    borderRadius: 98,
    fontSize: 16,
    fontWeight: 400,
    width: '100%',
    maxWidth: 360,
    height: 44,
    paddingLeft: 8,
    transition: 'max-width 0.5s ease-in-out',
    fontFamily: 'var(--design-body-text-font)',
  },
  searchOpen: {
    maxWidth: "50vw",
    '& fieldset': {
      border: '1px solid var(--design-primary-color) !important',
    },
  },
  searchMobile: {
    borderRadius: 8,
    height: 56,
    placeSelf: 'center',
    width: 'calc(100% - 32px)',
    maxWidth: '100%',
    paddingRight: 8,
    backgroundColor: '#fff',
  },
  input: {
    "&::placeholder": {
      color: '#94A3B8',
      opacity: 1,
    },
  },
  searchIcon: {
    fill: '#94A3B8',
    marginRight: 8,
  },
  closeIcon: {
    fill: '#94A3B8',
    cursor: 'pointer',
  },
  currencyOpenIcon: {
    fill: 'var(--design-secondary-color)',
  },
  currencyOpenIconMobile: {
    transform: 'rotate(90deg)',
    marginRight: 16,
  },
  select: {
    fontWeight: 700,
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: '19.5px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  currencySelectMobile: {
    padding: '16px',
    borderTop: '1px solid #E2E8F0',
    borderBottom: '1px solid #E2E8F0',
    backgroundColor: '#fff',
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  currencyPopoverMobile: {
    left: '0 !important',
  },
  paper: {
    border: '1px solid #E2E8F0',
    borderRadius: 8,
    boxShadow: '0px 2px 8px 0px #00000014',
    marginTop: 48,
    maxHeight: "422px",
    maxWidth: "520px",
    padding: "0 24px 24px 24px",
    overflowY: 'auto' as 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: '#CBD5E1',
      borderRadius: 2,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--design-primary-color)',
      borderRadius: 2,
    },
  },
  menuIcon: {
    cursor: 'pointer',
  },
  menuActiveItem: {
    fontSize: 14,
    padding: "4px 8px",
    fontFamily: 'var(--design-body-text-font)',
    color: 'var(--design-secondary-color)',
    opacity: .8,
  },
  drawerPaper: {
    width: '100vw',
    backgroundColor: '#F1F5F9',
  },
  closeButton: {
    position: 'absolute' as 'absolute',
    right: 16,
    top: 16,
    cursor: 'pointer',
    backgroundColor: '#fff',
  },
  list: {
    padding: "14px 0 0 0",
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column' as 'column',
    overflowY: 'auto' as 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: '#CBD5E1',
      borderRadius: 2,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--design-primary-color)',
      borderRadius: 2,
    },
  },
  listItem: {
    color: 'var(--design-secondary-color)',
    borderBottom: '1px solid #E2E8F0',
    wordWrap: 'break-word' as 'break-word',
    '&:first-child': {
      borderTop: '1px solid #E2E8F0',
    },
  },
  listItemText: {
    lineHeight: '1.5',
  },
  listItemTextBold: {
    lineHeight: '1.5',
    fontWeight: 600,
  },
  mobileLogin: {
    backgroundColor: '#fff',
    borderTop: '1px solid #E2E8F0',
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  searchPopper: {
    backgroundColor: '#fff',
    borderRadius: 16,
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    maxHeight: 422,
    overflowY: 'auto' as 'auto',
    '&::-webkit-scrollbar': {
      width: 20,
    },
    '&::-webkit-scrollbar-track': {
      background: '#CBD5E1',
      borderRadius: "0 16px 16px 0",
      borderRight: '16px solid #fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--design-primary-color)',
      borderRadius: "0 16px 16px 0",
      borderRight: '16px solid #fff',
    },
  },
  searchList: {
    padding: "16px 24px",
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  searchLoader: {
    margin: 'auto',
    color: 'var(--design-accent-color)',
    width: "24px !important",
    height: "24px !important",
  },
  searchListItem: {
    paddingLeft: 0,
    paddingRight: 0,
    wordWrap: 'break-word' as 'break-word',
    '&:not(:last-child)': {
      borderBottom: '1px solid #E2E8F0',
    },
    "&:hover": {
      backgroundColor: 'transparent'
    }
  },
});

export default withRouter(withStyles(styles)(AppHeader));

const headerData= [
  {
    categoryName: "Men",
    categoryItems: [
      {
        itemName: "T-Shirts",
        itemUrl: "/t-shirts",
      },
      {
        itemName: "Shirts",
        itemUrl: "/shirts",
      },
      {
        itemName: "Jeans",
        itemUrl: "/jeans",
      },
      {
        itemName: "Trousers",
        itemUrl: "/trousers",
      },
      {
        itemName: "Shoes",
        itemUrl: "/shoes",
      },
      {
        itemName: "Accessories",
        itemUrl: "/accessories",
      },
    ],
  },
  {
    categoryName: "Women",
    categoryItems: [
      {
        itemName: "Makeup",
        itemUrl: "/makeup",
      },
      {
        itemName: "Lipstick",
        itemUrl: "/lipstick",
      }
    ]
  },
  {
    categoryName: "Kids",
    categoryItems: [
      {
        itemName: "Toys",
        itemUrl: "/toys",
      },
      {
        itemName: "Games",
        itemUrl: "/games",
      }
    ]
  },
  {
    categoryName: "Home",
    categoryItems: [
      {
        itemName: "Furniture",
        itemUrl: "/furniture",
      },
      {
        itemName: "Decor",
        itemUrl: "/decor",
      }
    ]
  },
  {
    categoryName: "Haircare",
    categoryItems: [
      {
        itemName: "Shampoo",
        itemUrl: "/shampoo",
      },
      {
        itemName: "Conditioner",
        itemUrl: "/conditioner",
      }
    ]
  },
  {
    categoryName: "Fragrances",
    categoryItems: [
      {
        itemName: "Perfume",
        itemUrl: "/perfume",
      },
      {
        itemName: "Body Spray",
        itemUrl: "/body-spray",
      }
    ]
  },
]

const shoppingCartIconSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M16.5461 13C17.2961 13 17.9561 12.59 18.2961 11.97L21.8761 5.48C22.2461 4.82 21.7661 4 21.0061 4H6.20609L5.26609 2H1.99609V4H3.99609L7.59609 11.59L6.24609 14.03C5.51609 15.37 6.47609 17 7.99609 17H19.9961V15H7.99609L9.09609 13H16.5461ZM7.15609 6H19.3061L16.5461 11H9.52609L7.15609 6ZM7.99609 18C6.89609 18 6.00609 18.9 6.00609 20C6.00609 21.1 6.89609 22 7.99609 22C9.09609 22 9.99609 21.1 9.99609 20C9.99609 18.9 9.09609 18 7.99609 18ZM17.9961 18C16.8961 18 16.0061 18.9 16.0061 20C16.0061 21.1 16.8961 22 17.9961 22C19.0961 22 19.9961 21.1 19.9961 20C19.9961 18.9 19.0961 18 17.9961 18Z" fill="var(--design-secondary-color)"/>
  </svg> 
)

const appHeaderLogoSvg = (
  <svg width="150" height="14" viewBox="0 0 150 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 8.99609C0.17 12.1921 3.094 13.3141 8.007 13.3141C12.325 13.3141 15.198 12.4131 15.198 9.30209C15.198 6.12309 12.291 5.81709 7.837 5.47709C4.539 5.20509 3.961 4.96709 3.961 4.21909C3.961 3.55609 4.539 3.13109 7.123 3.13109C9.843 3.13109 11.016 3.53909 11.016 4.61009H14.688C14.586 1.90709 11.9 0.496094 7.225 0.496094C3.026 0.496094 0.289 1.63509 0.289 4.16809C0.289 6.85409 2.465 7.84009 7.378 8.18009C10.982 8.41809 11.526 8.57109 11.526 9.45509C11.526 10.2201 10.812 10.6791 7.888 10.6791C4.692 10.6791 3.689 10.0161 3.689 8.99609H0Z" fill="black"/>
<path d="M20.2312 5.40909V0.836093H16.7122V12.9741H20.2312V8.40109H28.3572V12.9741H31.8762V0.836093H28.3572V5.40909H20.2312Z" fill="black"/>
<path d="M41.2712 13.3141C46.3712 13.3141 49.1592 10.9171 49.1592 6.88809C49.1592 2.87609 46.3712 0.496094 41.2712 0.496094C36.1542 0.496094 33.3832 2.85909 33.3832 6.88809C33.3832 10.9341 36.1542 13.3141 41.2712 13.3141ZM41.2712 10.3221C38.2962 10.3221 37.0722 9.03009 37.0722 6.88809C37.0722 4.76309 38.2792 3.48809 41.2712 3.48809C44.2802 3.48809 45.4872 4.78009 45.4872 6.88809C45.4872 9.01309 44.2632 10.3221 41.2712 10.3221Z" fill="black"/>
<path d="M50.6599 12.9741H54.1789V9.86309H60.1969C63.1379 9.86309 64.9909 8.18009 64.9909 5.34109C64.9909 2.51909 63.1379 0.836093 60.1969 0.836093H50.6599V12.9741ZM59.2279 3.82809C60.1799 3.82809 61.3189 3.82809 61.3189 5.34109C61.3189 6.87109 60.1799 6.87109 59.2279 6.87109H54.1789V3.82809H59.2279Z" fill="black"/>
<path d="M79.8608 10.5771C80.7618 9.55709 81.3228 8.28209 81.4248 6.75209H78.6878C78.5688 7.72109 78.2968 8.50309 77.8718 9.11509L75.1348 7.12609C74.9308 6.97309 74.7098 6.83709 74.4888 6.70109C76.2738 6.10609 77.7358 5.35809 77.7358 3.72609C77.7358 1.80509 75.8658 0.734094 73.1628 0.734094C70.7318 0.734094 68.5048 1.72009 68.5048 3.82809C68.5048 5.00109 69.1168 5.86809 70.0518 6.68409C68.2498 7.29609 66.7538 8.14609 66.7538 9.99909C66.7538 12.2771 69.0828 13.3141 72.9928 13.3141C74.7608 13.3141 76.4098 12.9401 77.7698 12.2091L78.8408 12.9741H83.1418L79.8608 10.5771ZM73.3328 2.89309C74.2848 2.89309 74.9988 3.28409 74.9988 4.08309C74.9988 4.74609 74.5398 5.23909 72.9588 5.78309C72.1938 5.29009 71.5988 4.76309 71.5988 4.08309C71.5988 3.30109 72.2958 2.89309 73.3328 2.89309ZM73.4688 11.0361C71.2078 11.0361 70.0858 10.5261 70.0858 9.42109C70.0858 8.75809 70.5278 8.23109 71.4628 7.75509C72.0408 8.14609 72.6528 8.55409 73.2648 8.99609L75.6788 10.7131C75.0158 10.9341 74.2848 11.0361 73.4688 11.0361Z" fill="black"/>
<path d="M84.919 8.99609C85.089 12.1921 88.013 13.3141 92.926 13.3141C97.244 13.3141 100.117 12.4131 100.117 9.30209C100.117 6.12309 97.21 5.81709 92.756 5.47709C89.458 5.20509 88.88 4.96709 88.88 4.21909C88.88 3.55609 89.458 3.13109 92.042 3.13109C94.762 3.13109 95.935 3.53909 95.935 4.61009H99.607C99.505 1.90709 96.819 0.496094 92.144 0.496094C87.945 0.496094 85.208 1.63509 85.208 4.16809C85.208 6.85409 87.384 7.84009 92.297 8.18009C95.901 8.41809 96.445 8.57109 96.445 9.45509C96.445 10.2201 95.731 10.6791 92.807 10.6791C89.611 10.6791 88.608 10.0161 88.608 8.99609H84.919Z" fill="black"/>
<path d="M105.15 5.40909V0.836093H101.631V12.9741H105.15V8.40109H113.276V12.9741H116.795V0.836093H113.276V5.40909H105.15Z" fill="black"/>
<path d="M126.19 13.3141C131.29 13.3141 134.078 10.9171 134.078 6.88809C134.078 2.87609 131.29 0.496094 126.19 0.496094C121.073 0.496094 118.302 2.85909 118.302 6.88809C118.302 10.9341 121.073 13.3141 126.19 13.3141ZM126.19 10.3221C123.215 10.3221 121.991 9.03009 121.991 6.88809C121.991 4.76309 123.198 3.48809 126.19 3.48809C129.199 3.48809 130.406 4.78009 130.406 6.88809C130.406 9.01309 129.182 10.3221 126.19 10.3221Z" fill="black"/>
<path d="M135.579 12.9741H139.098V9.86309H145.116C148.057 9.86309 149.91 8.18009 149.91 5.34109C149.91 2.51909 148.057 0.836093 145.116 0.836093H135.579V12.9741ZM144.147 3.82809C145.099 3.82809 146.238 3.82809 146.238 5.34109C146.238 6.87109 145.099 6.87109 144.147 6.87109H139.098V3.82809H144.147Z" fill="black"/>
</svg>
)
// Customizable Area End
