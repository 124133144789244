import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
export const configJSON = require("./config");

// Customizable Area Start
import { showError } from '../../../ss-cms-common-components/src/Utilities/Utilities';
import { DialogProps } from '../../../ss-cms-common-components/src/Dialog/withDialog';
import {withLoaderProps} from '../../../ss-cms-common-components/src/HOC/withBrandingSpinner.web';
interface IUserLink {
  id: number;
  title: string;
  position: number;
  help_type: string;
  page_type_name: string;
}

export type Props = RouteComponentProps & 
  DialogProps & withLoaderProps & {
  classes?: Record<string,string>;
};

interface S {
  userLinks: IUserLink[];
}

interface SS {
  id: any;
}
// Customizable Area End

export default class FooterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetFooterItemsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
     userLinks:[],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const { apiResponseData } = this.getRosponseData(message);
      (apiResponseData === this.apiGetFooterItemsCallId) && (this.handleUserFullUrls(message));
    }
  }

  getRosponseData = (message:Message) => {
    const apiResponseData = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiSuccessMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiErrorMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    return ({ apiResponseData, apiSuccessMessageData, apiErrorMessageData });
  }

  makeAPIMessage = async (endPoint:string,method:string,) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    return message;
  }

  getMobileIndexDataFromServer = async () => {
    const message: Message = await this.makeAPIMessage(configJSON.getHelpCentreApiEndpoint, configJSON.httpGetType);
    this.apiGetFooterItemsCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

 

  handleUserFullUrls = (message: Message) => {
    const { apiSuccessMessageData, apiErrorMessageData } = this.getRosponseData(message);
    if(Array.isArray(apiSuccessMessageData) && !apiErrorMessageData){
      this.setState({userLinks: apiSuccessMessageData});      
    }
    else {
      showError(configJSON.netWorkErrorMessage,apiSuccessMessageData,this.props.hideLoader,this.props.setDialogState);    
    }
  }

   async componentDidMount(){
    await this.getMobileIndexDataFromServer();
  }

  correctUrl = (url: string) => {
    if (url.startsWith('http://')) {
      url = url.replace('http://', 'https://');
    } else if (!url.startsWith('https://')) {
      url = 'https://' + url;
    }
    return url;
  }
  
  // Customizable Area End
}
