Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "helpcentre";
exports.labelBodyText = "helpcentre Body";
exports.back = "Back";
exports.writeAQuestion = "Write a question or problem";
exports.helpCentre = "Help Centre";

exports.helpcentreAPIEndPoint = "/help_centre/question_type";
exports.httpGetType = "GET";

exports.btnExampleTitle = "CLICK ME";

exports.getHelpCentreApiEndpoint = "/bx_block_static_pages/mobile_indexing";
exports.getHelpCentreItemApiEndpoint = "/bx_block_static_pages/static_pages";
// Customizable Area End
