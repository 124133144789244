import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select, { SelectProps } from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

export const CustomInput = withStyles(() => ({
    input: {
        borderRadius: 8,
        border: "1px solid #CBD5E1",
        fontSize: 16,
        color: "#0F172A",
        padding: "10px 44px 10px 8px !important",
        fontFamily: "Inter",
        "&:focus": {
            borderRadius: 8,
            backgroundColor: "white"
        }
    }
}))(InputBase);

export interface VariantPropertySelectorProps {
    title: string;
    options: {
        value: string;
        label: string;
        disabled?: boolean;
    }[];
    onChange?: SelectProps["onChange"];
    onOpen?: SelectProps["onOpen"];
    value: string;
}

const VariantPropertySelector: React.FC<VariantPropertySelectorProps> = (props) => {

    return (
        <div className="variant-property-selector">
            <h6>{props.title}</h6>
            <Select
                onChange={props.onChange}
                onOpen={props.onOpen}
                value={props.value}
                input={<CustomInput />}
                IconComponent={KeyboardArrowDownRoundedIcon}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    PaperProps: {
                        style: {
                            color: "#0F172A"
                        }
                    }
                }}
            >
                {
                    props.options.map(option => (
                        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                            {option.label}
                        </MenuItem>
                    ))
                }
            </Select>
        </div>

    );
};

export default VariantPropertySelector;
// Customizable Area End