import React from 'react'
// Customizable Area Start
import FooterController, { configJSON } from './FooterController.web';
import './assets/Footer.css';
import { withRouter } from 'react-router-dom';
import { withDialog } from '../../../ss-cms-common-components/src/Dialog/withDialog';
import withLoader from '../../../ss-cms-common-components/src/HOC/withBrandingSpinner.web';
const image_playstorelogo = require('./assets/googleplay.png');
const image_appstorelogo = require('./assets/appstore.png');
interface IUserLink {
  id: number;
  title: string;
  position: number;
  help_type: string;
  page_type_name: string;
}
// Customizable Area End

class Footer extends FooterController {
  // Customizable Area Start
  designDetails = JSON.parse(localStorage.getItem('designDetails') 
    || '{ "footer": { "showContactDetails": true, "showSocialLinks": true, "showAppLinks": true, "copyRightText": "© 2021 All rights reserved" } }');
  customerServices = JSON.parse(localStorage.getItem('customerServices') || '{ "customerPhone": "", "customerEmail": "", "customerAddress": "" }');
  
  renderSocialLinks = () => {
    return (
      <div className="AppFooter__FollowUsOn">
        <span>{configJSON.followUsOnTitle}</span>
        <ul>
          {this.designDetails.footer.facebookUrl && 
          <li><a target='_blank' href={this.correctUrl(this.designDetails.footer.facebookUrl)}><div>{facebookSvg}</div><p>{configJSON.facebook}</p></a></li>}
          {this.designDetails.footer.twitterUrl && 
            <li><a target='_blank' href={this.correctUrl(this.designDetails.footer.twitterUrl)}><div>{twitterSvg}</div><p>{configJSON.twitter}</p></a></li>}
          {this.designDetails.footer.instagramUrl && 
            <li><a target='_blank' href={this.correctUrl(this.designDetails.footer.instagramUrl)}><div>{instagramSvg}</div><p>{configJSON.instagram}</p></a></li>}
          {this.designDetails.footer.linkedInUrl && 
            <li><a target='_blank' href={this.correctUrl(this.designDetails.footer.linkedInUrl)}><div>{linkedinSvg}</div><p>{configJSON.linkedin}</p></a></li>}
          {this.designDetails.footer.youtubeUrl && 
            <li><a target='_blank' href={this.correctUrl(this.designDetails.footer.youtubeUrl)}><div>{youtubeSvg}</div><p>{configJSON.youtube}</p></a></li>}
        </ul>
      </div>
    )
  }

  renderAppLinks = () => {
    return (
      <div className="AppFooter__DownloadApp">
        <span>{configJSON.downloadAppTitle}</span>
        <ul>
          {this.designDetails.footer.playStoreUrl &&
            <li><a href={this.correctUrl(this.designDetails.footer.playStoreUrl)} target='_blank'><img src={image_playstorelogo} alt="playstore logo" style={{width: '120px'}} /></a></li>}
          {this.designDetails.footer.appStoreUrl &&
            <li><a href={this.correctUrl(this.designDetails.footer.appStoreUrl)} target='_blank'><img src={image_appstorelogo} alt="appstore logo" style={{width: '120px'}} /></a></li>}
        </ul>
      </div>
    )
  }
  
  render() {
    return (
      <div className='AppFooter'>
        <div className="AppFooter__QuickLinks">
          <span>{configJSON.quickLinksTitle}</span>
          {configJSON.quickLinks.map((item: { id: string, url: string, title: string }) => (
            <ul key={item.id}>
              <li><a href={item.url}>{item.title}</a></li>
            </ul>
          ))}
        </div>
        <div className="AppFooter__UsefulLinks">
          <span>{configJSON.usefulLinksTitle}</span>
          {this.state.userLinks.length > 0 && this.state.userLinks.map((item: IUserLink) => (
            <ul key={item.id}>
              <li><a href={`${configJSON.helpCenter}${item.id}`}>{item.title}</a></li>
            </ul>
          ))}
        </div>
        {this.designDetails.footer.showContactDetails && (
          <div className="AppFooter__CustomerService">
            <span>{configJSON.contactUsTitle}</span>
            {this.customerServices.customerPhone && (
              <ul>
                <li>
                  <a href={`tel:${this.customerServices.customerPhone}`}>
                    {configJSON.phoneTitle} {this.customerServices.customerPhone}
                  </a>
                </li>
              </ul>
            )}
            {this.customerServices.customerEmail && (
              <ul>
                <li>
                  <a href={`mailto:${this.customerServices.customerEmail}`}>
                    {configJSON.emailTitle} {this.customerServices.customerEmail}
                  </a>
                </li>
              </ul>
            )}
            {this.customerServices.customerAddress && (
              <ul>
                <li>
                  {configJSON.addressTitle} {this.customerServices.customerAddress}
                </li>
              </ul>
            )}
          </div>
        )}
    
        {this.designDetails.footer.showSocialLinks && this.renderSocialLinks()}
        {this.designDetails.footer.showAppLinks && this.renderAppLinks()}
        <div className="AppFooter__CopyRight">
          <span>{this.designDetails.footer.copyRightText}</span>
        </div>
      </div>
    )
  }
  // Customizable Area End
}

// Customizable Area Start
export default withRouter(withDialog(withLoader(Footer)));

const facebookSvg = (
  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M3.58438 17.4212L3.56024 10.0528H0.402344V6.89489H3.56024V4.78963C3.56024 1.94836 5.31972 0.579102 7.85432 0.579102C9.06842 0.579102 10.1119 0.669491 10.416 0.709891V3.67918L8.65809 3.67998C7.27964 3.67998 7.01273 4.335 7.01273 5.2962V6.89489H10.9287L9.87603 10.0528H7.01272V17.4212H3.58438Z" fill="black"/>
</svg>
)

const twitterSvg = (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M18 2.375C17.325 2.7125 16.65 2.825 15.8625 2.9375C16.65 2.4875 17.2125 1.8125 17.4375 0.9125C16.7625 1.3625 15.975 1.5875 15.075 1.8125C14.4 1.1375 13.3875 0.6875 12.375 0.6875C10.4625 0.6875 8.775 2.375 8.775 4.4C8.775 4.7375 8.775 4.9625 8.8875 5.1875C5.85 5.075 3.0375 3.6125 1.2375 1.3625C0.9 1.925 0.7875 2.4875 0.7875 3.275C0.7875 4.5125 1.4625 5.6375 2.475 6.3125C1.9125 6.3125 1.35 6.0875 0.7875 5.8625C0.7875 7.6625 2.025 9.125 3.7125 9.4625C3.375 9.575 3.0375 9.575 2.7 9.575C2.475 9.575 2.25 9.575 2.025 9.4625C2.475 10.925 3.825 12.05 5.5125 12.05C4.275 13.0625 2.7 13.625 0.9 13.625C0.5625 13.625 0.3375 13.625 0 13.625C1.6875 14.6375 3.6 15.3125 5.625 15.3125C12.375 15.3125 16.0875 9.6875 16.0875 4.85C16.0875 4.7375 16.0875 4.5125 16.0875 4.4C16.875 3.8375 17.55 3.1625 18 2.375Z" fill="black"/>
</svg>
)

const instagramSvg = (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.87563 5.11816C7.33531 5.11816 5.23047 7.18671 5.23047 9.76333C5.23047 12.3399 7.29902 14.4085 9.87563 14.4085C12.4522 14.4085 14.5208 12.3036 14.5208 9.76333C14.5208 7.223 12.416 5.11816 9.87563 5.11816ZM9.87563 12.7391C8.24257 12.7391 6.89982 11.3964 6.89982 9.76333C6.89982 8.13026 8.24257 6.78752 9.87563 6.78752C11.5087 6.78752 12.8514 8.13026 12.8514 9.76333C12.8514 11.3964 11.5087 12.7391 9.87563 12.7391Z" fill="black"/>
<path d="M14.7009 6.06187C15.2821 6.06187 15.7533 5.59069 15.7533 5.00945C15.7533 4.42822 15.2821 3.95703 14.7009 3.95703C14.1196 3.95703 13.6484 4.42822 13.6484 5.00945C13.6484 5.59069 14.1196 6.06187 14.7009 6.06187Z" fill="black"/>
<path d="M17.4234 2.28771C16.4798 1.30787 15.1371 0.799805 13.6129 0.799805H6.1371C2.97984 0.799805 0.875 2.90464 0.875 6.0619V13.5014C0.875 15.0619 1.38306 16.4046 2.39919 17.3845C3.37903 18.328 4.68548 18.7998 6.17339 18.7998H13.5766C15.1371 18.7998 16.4435 18.2917 17.3871 17.3845C18.3669 16.4409 18.875 15.0982 18.875 13.5377V6.0619C18.875 4.53771 18.3669 3.23126 17.4234 2.28771ZM17.2782 13.5377C17.2782 14.6627 16.879 15.57 16.2258 16.1869C15.5726 16.8038 14.6653 17.1305 13.5766 17.1305H6.17339C5.08468 17.1305 4.17742 16.8038 3.52419 16.1869C2.87097 15.5337 2.54435 14.6264 2.54435 13.5014V6.0619C2.54435 4.97319 2.87097 4.06593 3.52419 3.41271C4.14113 2.79577 5.08468 2.46916 6.17339 2.46916H13.6492C14.7379 2.46916 15.6452 2.79577 16.2984 3.449C16.9153 4.10222 17.2782 5.00948 17.2782 6.0619V13.5377Z" fill="black"/>
</svg>
)

const linkedinSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4037 2H2.52174C2.2236 2 2 2.2236 2 2.52174V13.4783C2 13.7019 2.2236 13.9255 2.52174 13.9255H13.4783C13.7764 13.9255 14 13.7019 14 13.4037V2.52174C13.9255 2.2236 13.7019 2 13.4037 2ZM5.50319 12.1366H3.7889V6.47205H5.57772V12.1366H5.50319ZM4.68329 5.72671C4.08702 5.72671 3.63981 5.20497 3.63981 4.68323C3.63981 4.08696 4.08702 3.63975 4.68329 3.63975C5.27956 3.63975 5.72677 4.08696 5.72677 4.68323C5.65223 5.20497 5.20503 5.72671 4.68329 5.72671ZM12.1366 12.1366H10.3478V9.37888C10.3478 8.70808 10.3478 7.8882 9.45339 7.8882C8.55898 7.8882 8.40991 8.63354 8.40991 9.37888V12.2112H6.62109V6.47205H8.33538V7.21739C8.55898 6.77019 9.15525 6.32298 9.97513 6.32298C11.764 6.32298 12.0621 7.51553 12.0621 9.00621V12.1366H12.1366Z" fill="black"/>
  </svg>
)

const youtubeSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 14 10" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.65582 0.43457H11.3427C12.5698 0.43457 13.5649 1.42968 13.5649 2.65679V7.30326C13.5649 8.53037 12.5698 9.52548 11.3427 9.52548H2.65582C1.4287 9.52548 0.433594 8.53037 0.433594 7.30326V2.65679C0.433594 1.42968 1.4287 0.43457 2.65582 0.43457ZM5.58511 7.00023L7.55481 5.99013L9.5245 4.98002L5.58511 2.95982V7.00023Z" fill="black"/>
  </svg>
)

// Customizable Area End