import React from "react";
// Customizable Area Start
import { Link, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from "@material-ui/core";
import ProductDescriptionController, { configJSON } from "./ProductDescriptionController";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CameraAltOutlined from '@material-ui/icons/CameraAltOutlined';
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import ImageGalleryNavIcon from "./ImageGalleryNavIcon";
import VariantPropertySelector from "./VariantPropertySelector";
import { Helmet } from "react-helmet";

import "../assets/style.css";
// Customizable Area End

export class ProductDescription extends ProductDescriptionController {

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const productData = this.state.productData;
    const variantId = this.props.navigation.getParam("variantId");
    const variantData = variantId ? productData?.attributes.catalogue_variants?.find((variant: any) => variant.id === variantId) : undefined;
    let images, addToBagDisabled:boolean, realPrice, salePrice, onSale, discountRate;
    if (variantData) {
      images = variantData.attributes.images;
      addToBagDisabled = variantData.attributes.stock_qty <= 0;
      realPrice = variantData.attributes.price;
      salePrice = variantData.attributes.sale_price;
      onSale = Number(variantData.attributes.discount) > 0;
      discountRate = variantData.attributes.discount;
    } else {
      images = productData?.attributes.images;
      addToBagDisabled = productData?.attributes.stock_qty <= 0;
      realPrice = productData?.attributes.price;
      salePrice = productData?.attributes.sale_price;
      onSale = productData?.attributes.on_sale;
      discountRate = productData?.attributes.discount;
    }
    const renderAddToBag = (
      <>
        {this.state.loader ? 
        <CircularProgress disableShrink  className="circular-progress"/> :
        <button className="add-to-bag-button" data-test-id="addToBag" onClick={()=>this.handleAddItemToCartButton()} disabled={addToBagDisabled}>{`${this.getAddToBagBtnText()}`}</button>
        }
      </>
    )
    // Customizable Area End
    return (
      // Customizable Area Start
      productData ? (
        <div className="product-description">
          <Helmet>
            <title>{productData.attributes.search_engine_listing?.title}</title>
            <meta name="description" content={productData.attributes.search_engine_listing?.description} />
          </Helmet>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{color:"var(--design-secondary-color)"}}/>} aria-label="breadcrumb" className="products-description-category">
            <Link href="/" className="products-description-category-link">{configJSON.home}</Link>
            <Link href="/catalogue" className="products-description-category-link">{configJSON.allproducts}</Link>
            <Link 
              className="products-description-category-link" 
              href={`/catalogue?${configJSON.queryParameters.Category}=${productData.attributes.category.id}`}
              onClick={() => this.handleBreadCrumbClick(productData.attributes.category.name, "")}
            >{productData.attributes.category.name}</Link>
            <Link 
              className="products-description-category-link" 
              href={`/catalogue?${configJSON.queryParameters.Category}=${productData.attributes.category?.id}`
                +`&${configJSON.queryParameters.SubCategory}=${productData.attributes.selected_sub_category?.id}`}
              onClick={() => this.handleBreadCrumbClick(productData.attributes.category?.name, productData.attributes.selected_sub_category?.name)}
            >{productData.attributes.selected_sub_category?.name}</Link>
          </Breadcrumbs>
          <div className="content-wrapper">
            <div className="product-info-wrapper">
              <div className="product-images-area">
                {
                  images?.length > 0 ? (
                    <ImageGallery
                      data-testid="image-gallery"
                      items={images.map((image: any) => ({
                        original: image.url,
                        thumbnail: image.url
                      })) as ReactImageGalleryItem[]}
                      lazyLoad
                      thumbnailPosition="left"
                      showPlayButton={false}
                      showFullscreenButton={false}
                      showBullets={["sm", "md"].includes(this.state.screenSize)}
                      showThumbnails={["lg", "xl"].includes(this.state.screenSize)}
                      showNav={["lg", "xl"].includes(this.state.screenSize)}
                      renderLeftNav={(onClick, disabled) => (
                        <ImageGalleryNavIcon direction="left" onClick={onClick} disabled={disabled} />
                      )}
                      renderRightNav={(onClick, disabled) => (
                        <ImageGalleryNavIcon direction="right" onClick={onClick} disabled={disabled} />
                      )}
                    />
                  ) : (
                    <div className="no-image-available">
                      <CameraAltOutlined />
                      <span>{configJSON.noImageAvailable}</span>
                    </div>
                  )
                }
              </div>
              <div className="product-details-area">
                <h1 className="product-title">
                  {productData.attributes.name}
                </h1>
                <div className="product-price">
                  {this.state.currencySymbol} {Number(salePrice).toFixed(2)}
                </div>
                {
                  onSale && (
                    <div className="non-discounted-price-wrapper">
                      <span className="non-discounted-price">
                        {this.state.currencySymbol} {Number(realPrice).toFixed(2)}
                      </span>
                      <span className="discount-percentage">
                        {Number(discountRate)}% {configJSON.off}
                      </span>
                    </div>
                  )
                }
                {[ "lg", "xl" ].includes(this.state.screenSize) && (
                  <>
                    <h4 className="description-title">
                      {configJSON.description}
                    </h4>
                    <div
                      className="description-content"
                      dangerouslySetInnerHTML={{ __html: productData.attributes.description ?? "" }}
                    />
                  </>
                )}
                {
                  Array.isArray(productData.attributes.catalogue_variants) && (
                    <div className="variant-selection-area">
                      {
                        this.state.variantOptions.map(variantOption => (
                          <VariantPropertySelector
                            data-testid="variant-property-selector"
                            key={variantOption.id}
                            title={variantOption.name}
                            options={variantOption.optionsList}
                            value={variantOption.optionsList.find((option: any) => option.isSelected)?.value}
                            onChange={event => this.handleOptionChange(variantOption, event.target.value as string)}
                            onOpen={() => this.handleOptionClick(variantOption)}
                          />
                        ))
                      }
                    </div>
                  )
                }

                <div className="buttons-wrapper">
                  {addToBagDisabled ?
                  <p className="out-of-stock">{configJSON.outofStock}</p>
                   :
                   renderAddToBag
                  }
                  <div className="hide-div-element" style={{ width: 56 }} />
                </div>

                {["sm", "md"].includes(this.state.screenSize) && (
                  <>
                  <h4 className="description-title">
                    {configJSON.description}
                  </h4>
                  <div
                    className="description-content"
                    dangerouslySetInnerHTML={{ __html: productData.attributes.description ?? "" }}
                  />
                </>)}

                {productData.attributes.extra_infos.map((info: any) => (
                  <div className="accordions-wrapper">
                    <Accordion square elevation={0}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ color: "var(--design-secondary-color)",fontFamily:"var(--design-body-text-font)" }} expandIcon={<ExpandMoreIcon style={{
                        color: "#0F172A"
                      }} />}>
                        {info.title}
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0, borderTop: "1px solid #E2E8F0", borderBottom: 'none' }}>
                        <p key={info.id} style={{ display: "flex", alignItems: "center", flexWrap: "wrap", wordBreak: "break-word" }}>
                          <span dangerouslySetInnerHTML={{ __html: info.description }} />
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="product-description loader-main-div">
          <CircularProgress style={{ margin: "auto", width: 50, height: 50,color:"var(--design-primary-color)" }} />
        </div>
      )
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default ProductDescription;
// Customizable Area End