import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import { isEqual } from "lodash";
import { eventEmitter } from "../../../framework/src/utils/EventEmitter";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  productData?: any;
  variantOptions: any[];
  screenSize: "sm" | "md" | "lg" | "xl";
  cartId: number;
  currencySymbol:string;
  addedToBag: boolean;
  itemExistsInCart: boolean;
  cartItemCount: number;
  loader:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = "";
  createCartApiCallId: string = "";
  addItemToCartApiCallId: string = "";
  getStoreDetailsProductCallId: string = "";
  getCartItemsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      screenSize: "sm",
      variantOptions: [],
      cartId: 0,
      currencySymbol:"",
      addedToBag: false,
      itemExistsInCart: false,
      cartItemCount: 0,
      loader:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.productApiItemCallId) {
        if (responseJson?.data) {
          const catalogueId = responseJson.data.attributes.catalogue_id;
          const variantId = responseJson.data.attributes.variant_id;
          this.handleVarRedirect(catalogueId, variantId);
          this.setState({
            productData: responseJson.data,
            variantOptions: this.generateVariantOptions(responseJson.data),
          });
        } 
      } else if (apiRequestCallId === this.getStoreDetailsProductCallId) {
       this.handleStoreDetailsProductResponse(responseJson)
      } else if (apiRequestCallId === this.addItemToCartApiCallId) {      
        this.handleAddItemToCartResponse(responseJson)
        } else if (apiRequestCallId === this.createCartApiCallId) {
           this.handleCreateCartResponse(responseJson)
      } else if (apiRequestCallId === this.getCartItemsCallId) {
        this.handleCartItemsCallIdResponse(responseJson)
      } else {
        this.parseApiErrorResponse(errorResponse);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleVarRedirect = (catalogueId: string, variantId: string) => {
    const variants = this.state.productData?.attributes.catalogue_variants;
    const urlVarId = this.props.navigation.getParam("variantId");
    if (catalogueId !== variantId && !urlVarId && !variants?.find((variant: any) => variant.id === urlVarId)) {
      this.navigateToVariant(catalogueId, variantId);
    }
  }

  handleCartItemsCallIdResponse = (responseJson: any) => {
    if(responseJson?.data) {
      const cartItemsId = responseJson?.data?.attributes?.cart_items?.map((item: any) => 
      (item.attributes.catalogue.id))
      const productId = this.props.navigation.getParam("id")
      const isExist = cartItemsId?.includes(Number(productId))
      const count = responseJson?.data.attributes.cart_items_count
      this.setState({ itemExistsInCart: isExist, cartItemCount: count })    
    }
  }

  handleAddItemToCartResponse = async (responseJson: any) => {
    if(responseJson?.data) {
      this.setState({ addedToBag: true,loader:false })
      if(!this.state.itemExistsInCart){
        const count = this.state.cartItemCount + 1;
        eventEmitter.dispatch("badgeValue", count);      
      }
    }
  }

  handleCreateCartResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({cartId: responseJson.data.attributes.id})
      setStorageData('cartId', responseJson.data.attributes.id);
      this.addItemToCartApi()
     }
  }

  handleStoreDetailsProductResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ currencySymbol: responseJson.data.attributes.currency_symbol });
    } 
  }

  getStoreDetailsAtCatalogue = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMesag = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStoreDetailsProductCallId = requestMesag.messageId;

    requestMesag.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.storeDetailsEndpoint
    );

    requestMesag.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMesag.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );

    runEngine.sendMessage(requestMesag.id, requestMesag);
  };

  handleResize = () => {
    const screenWidth = document.documentElement.clientWidth ?? 0;
    let currentScreenSize: S["screenSize"] = "xl";

    if (screenWidth <= 480) {
      currentScreenSize = "sm";
    }
    else if (screenWidth <= 768) {
      currentScreenSize = "md";
    }
    else if (screenWidth <= 992) {
      currentScreenSize = "lg"
    }

    if (currentScreenSize !== this.state.screenSize) {
      this.setState({ screenSize: currentScreenSize })
    }
  }

  async componentDidMount() {
    let cartIdExist = localStorage.getItem('cartId');
    if(cartIdExist) {
      this.getCartItemCount()
    }
    this.getStoreDetailsAtCatalogue()
    this.getProductDetails();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };

  getProductDetails = () => {
    const header = { "Content-Type": configJSON.productApiContentType };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productApiItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}/${this.props.navigation.getParam("id")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddItemToCartButton = async () => {
    if(this.state.addedToBag) {
      window.location.href = `${window.location.origin}/shopping-cart`
    } else {
      const cartIdExist = await getStorageData('cartId');
      this.setState({loader:true})
      if(!cartIdExist) {
        this.createCartApi();
      } else {
        this.addItemToCartApi()
      }
    }       
  }

  getCartItemCount = async () => {
    let cartId = await localStorage.getItem('cartId');
    
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createCartApiEndPoint}/${cartId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };

  addItemToCartApi = async () => {
    const header = { "Content-Type": configJSON.productApiContentType };
    const cartID = await getStorageData('cartId')
    const body = {
      cart_items: [
              {
                product_id: this.props.navigation.getParam("id"),
                cart_id: cartID,
                quantity : 1,
                catalogue_variant_id: this.props.navigation.getParam("variantId")
              }
          ]
  }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addItemToCartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addItemToCartApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createCartApi = () => {
    const header = { "Content-Type": configJSON.productApiContentType };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createCartApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTargetVariant = (variantOption: any, value: string) => {
    const productData = this.state.productData;
    const selectedOptionsList = this.state.variantOptions.map((option: any) => ({
      parentValue: option.id,
      value: option.id === variantOption.id ? value : option.optionsList.find((property: any) => property.isSelected)?.value
    }));

    return this.findVariantByOptions(productData.attributes.catalogue_variants, selectedOptionsList)
  }

  handleOptionChange = (variantOption: any, newValue: string) => {
    const targetVariant = this.getTargetVariant(variantOption, newValue);
    if (targetVariant) {
      variantOption.optionsList.forEach((option: any) => {
        option.isSelected = newValue === option.value
      });
      this.setState({ variantOptions: [...this.state.variantOptions], addedToBag: false });
      this.navigateToVariant(this.state.productData.id, targetVariant.id);
    }
  }

  handleOptionClick = (variantOption: any) => {
    const variants = this.state.productData.attributes.catalogue_variants;
    const otherSelectedOptions = this.state.variantOptions.filter(option => option.id !== variantOption.id)
    .map(option => ({
      parentValue: option.id,
      value: option.optionsList.find((property: any) => property.isSelected).value
    }));
    variantOption.optionsList.forEach((option: any) => {
      const variantToCheck = [option, ...otherSelectedOptions];
      const variant = this.findVariantByOptions(variants, variantToCheck);
      option.disabled = !variant;
    });

    this.setState({ variantOptions: [...this.state.variantOptions] });
  }

  navigateToVariant = (productId: string | number, variantId?: string | number) => {
    this.props.navigation.navigate("ProductDescription", variantId ? {
      id: productId,
      variantId
    } : { id: productId });
  }

  setSelectedOptions = (variants: any[], requestedVariantId: string, variantOptionsList: any[]) => {
    // set selected items of the dropdowns by the requested variant
    const requestedVariant = variants.find((variant: any) => variant.id === requestedVariantId);
    if (requestedVariant) {
      const variantProperties: any[] = requestedVariant.attributes.catalogue_variant_properties?.data;
      variantProperties.forEach(property => {
        const selectedOption = variantOptionsList.find(variantOption => variantOption.id === property.attributes.variant_option.name);
        if (selectedOption) {
          selectedOption.optionsList.forEach((item: any) => {
            item.isSelected = item.value === property.attributes.name;
          });
        }
      });
    }
  }

  setVariantProperties = (variantProperties: any[], variantOptionsList: any[]) => {
    variantProperties.forEach(property => {
      const dropdownOption = {
        label: property.attributes.name,
        value: property.attributes.name,
        parentValue: property.attributes.variant_option.name,
      };
      const option = variantOptionsList.find(option => option.id === property.attributes.variant_option.name);
      if (option) {
        const optionProperty = option.optionsList.find((property: any) => property.value === dropdownOption.value);
        if (!optionProperty) {
          option.optionsList.push(dropdownOption);
        }
      }
      else {
        variantOptionsList.push({
          id: property.attributes.variant_option.name,
          name: property.attributes.variant_option.name,
          optionsList: [dropdownOption]
        });
      }
    });
  }

  generateVariantOptions = (productData?: any) => {
    // generate unique options for variant dropdowns
    const variantOptions: any[] = [];
    const variants = productData?.attributes.catalogue_variants;
    if (Array.isArray(variants)) {
      const requestedVariantId = this.props.navigation.getParam("variantId");
      variants.forEach(variant => {
        const variantProperties = variant.attributes.catalogue_variant_properties?.data;
        if (Array.isArray(variantProperties)) {
          this.setVariantProperties(variantProperties, variantOptions);
        }
      })
      this.setSelectedOptions(variants, requestedVariantId, variantOptions);
    }
    return variantOptions;
  }

  variantOptionsSorter = (opt1: any, opt2: any) => opt1.parentValue.localeCompare(opt2.parentValue);

  findVariantByOptions = (variants: any[], selectedOptionsList: any[]) => {
    const requestingVariant = selectedOptionsList.map(option => ({
      parentValue: option.parentValue,
      value: option.value
    }));
    requestingVariant.sort(this.variantOptionsSorter);

    return variants.find(variant => {
      let productVariants: any[] = variant.attributes.catalogue_variant_properties.data.map((variantProp: any) => ({
        parentValue: variantProp.attributes.variant_option.name,
        value: variantProp.attributes.name
      }));
      productVariants = productVariants.filter((property: any, index: number, self: any) => self.findIndex((p: any) => p.parentValue === property.parentValue) === index);
      productVariants.sort(this.variantOptionsSorter);
      return isEqual(requestingVariant, productVariants);
    });
  }

  getAddToBagBtnText = () => {
    return this.state.addedToBag ? "Go to bag" : "Add to bag"
  }

  handleBreadCrumbClick = (category: string, subCategory: string) => {
    localStorage.setItem('category', category);
    localStorage.setItem('subCategory', subCategory);
  }
  // Customizable Area End
}
