
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export enum StaticPagesType {
  AboutUs = "about_us",
  FAQ = "faq",
  TermsOfService = "terms_of_service",
  PrivacyPolicy = "privacy_policy",
  HowItWorks = "how_it_works",
  DeliveryAndReturns = "delivery_and_returns",
}

export interface StaticPage {
  id: number;
  title: string;
  help_type: StaticPagesType;
}

export interface ActivePageItem {
  title: string;
  description: string;
}
// Customizable Area End
 
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mainPageOpen: boolean
  staticPagesItems: StaticPage[];
  filteredStaticPagesItems: StaticPage[];
  activePage: StaticPage;
  activePageItems: ActivePageItem[];
  searchValue: string;
  isLoading: boolean;
  isMobile: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HelpCentreController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getHelpCentreApiCallId: string = ""
  getHelpCentreItemsApiCallId: string = ""
  getHelpCentreItemApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)];
    this.state = {
      mainPageOpen: true,
      staticPagesItems: [],
      filteredStaticPagesItems: [],
      activePage: {} as StaticPage,
      activePageItems: [],
      searchValue: "",
      isLoading: true,
      isMobile: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if(this.isPlatformWeb()){
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.getHelpCentre();
  }
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  // Customizable Area End

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getHelpCentreItemsApiCallId) {
        const linkId = window.location.href.split("?").at(-1);
        let activePage = responseJson[0]
        if(Array.isArray(responseJson)){
         const newActivePage = responseJson.find(each => {
            return each.id === parseInt(linkId as string);
          }) 
          activePage = newActivePage || activePage;
        }
        this.setState({
          isLoading: false,
          staticPagesItems: responseJson,
          filteredStaticPagesItems: responseJson,
          activePage:  activePage
        }, this.getHelpCentreItem.bind(this, responseJson[0].id))
      } else if (apiRequestCallId === this.getHelpCentreItemApiCallId) {
        if (this.state.activePage.help_type === StaticPagesType.FAQ) {
          const faqDataArr = responseJson.data.attributes.questions.map((faqData:{question:string,answer:string}) => {
            return {
              title: faqData.question,
              description: faqData.answer
            }
          });
          this.setState({ activePageItems: faqDataArr, isLoading: false });
        } else {
          const { title, description } = responseJson.data.attributes;
          this.setState({ activePageItems: [{ title, description }], isLoading: false });
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleMenuItemClick = (item: StaticPage) => {
    if (item.id === this.state.activePage.id && !this.state.isMobile) return;
    this.setState({ mainPageOpen: false, activePage: item }, this.getHelpCentreItem.bind(this, item.id))
  }

  handleBreadcrumbItemClick = () => {
    this.setState({ mainPageOpen: true })
  }

  handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      this.setState({ filteredStaticPagesItems: this.state.staticPagesItems });
    }
    this.setState({ searchValue: event.target.value }, () => {
      this.setState({ filteredStaticPagesItems: 
        this.state.staticPagesItems.filter((item: StaticPage) => item.title.toLowerCase().includes(this.state.searchValue.toLowerCase())) 
      });
    });
  }

  getHelpCentre = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHelpCentreItemsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getHelpCentreApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getHelpCentreItem = (itemId: number) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHelpCentreItemApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getHelpCentreItemApiEndpoint}/${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  handleDirectToHome = () => {
    window.location.href = "/";
  }
  // Customizable Area End
}
