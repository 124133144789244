Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.quickLinksTitle = 'Quick Links';
exports.usefulLinksTitle = 'Useful Links';
exports.followUsOnTitle = 'Follow us';
exports.downloadAppTitle = 'Download App';
exports.customerServiceTitle = 'Customer Service';
exports.googlePlayUrl = 'https://play.google.com/store/apps';
exports.appStoreUrl = 'https://www.apple.com/in/ios/app-store/';
exports.copyRightText = 'Copyright © 2021 Studio Store. All rights reserved Powered by ';
exports.builderAiText = 'Builder.ai';

exports.quickLinks = [{
  id: 1,
  title: 'About us',
  url: '/about-us'
}, {
  id: 2,
  title: 'Contact us',
  url: '/contact-us'
}, {
  id: 3,
  title: 'Help & Support',
  url: '/help-centre'
}];

exports.usefulLinks = [{
  id: 1,
  title: 'Terms & Conditions',
  url: '/help-centre?page=terms-and-conditions'
},{
  id: 2,
  title: 'Refund Policy',
  url: '/help-centre?page=refund-policy'
}, {
  id: 3,
  title: 'FAQ',
  url: '/help-centre?page=faq'
},
{
  id: 4,
  title: 'Help & Support',
  url: '/help-centre'
}
];

exports.customerServices = [{
  id: 1,
  title: 'Phone : (+44) 1234-567-89'
}, {
  id: 2,
  title: 'Email : info@builder.ai',
}, {
  id: 3,
  title: 'Address: 6th Floor, North West House, 119 Marylebone Rd, London NW1 5PU.1.'
}];

exports.facebook = "Facebook";
exports.twitter = "Twitter";
exports.instagram = "Instagram";
exports.linkedin = "Linkedin";
exports.youtube = "Youtube";
exports.contactUsTitle = "Contact Us";
exports.phoneTitle = "Phone:";
exports.emailTitle = "Email :";
exports.addressTitle = "Address :";
exports.validationApiContentType = "application/json";
exports.getHelpCentreApiEndpoint = "/bx_block_static_pages/mobile_indexing";
exports.httpGetType = "GET";
exports.netWorkErrorMessage = "Opps! Network error";
exports.helpCenter = "/help-centre?"
// Customizable Area End