import React, { Component, Suspense, lazy } from "react";
// Customizable Area Start
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const Catalogue = lazy(() => import("../../../blocks/catalogue/src/Catalogue.web"));
const LandingPage = lazy(() => import("../../../blocks/landingpage/src/LandingPage.web"));
const ContactUs = lazy(() => import("../../contactus/src/ContactUs.web"));
const ShoppingCart = lazy(() => import("../../shoppingcart/src/ShoppingCart.web"));
const PaymentSuccessScreen = lazy(() => import("../../shoppingcart/src/PaymentSuccessScreen"))
const PaymentFailure = lazy(() => import("../../shoppingcart/src/PaymentFailure.web"));
const Tappaychargeidloader = lazy(() => import("../../tappaymentsintegration/src/Tappaychargeidloader.web"));
/* Admin Routes */
const EmailAccountLogin = lazy(() => import("../../../blocks/emailaccountlogin/src/Login.web"));
const EmailAccountForgotPassword = lazy(() => import("../../../blocks/emailaccountforgotpassword/src/ForgotPassword.web"));
const AdminEmailTemplates = lazy(() => import("../../../blocks/adminemailtemplates/src/EmailTemplates.web"));
const AdminEmailTemplatesCreate = lazy(() => import("../../../blocks/adminemailtemplates/src/EmailTemplatesCreate.web"));
const AdminStaticPage = lazy(() => import("../../../blocks/adminstaticpage/src/StaticPageTable.web"));
const AdminStaticPageCreate = lazy(() => import("../../../blocks/adminstaticpage/src/CreateStaticPage.web"));
const AdminImageCatalogue = lazy(() => import("../../../blocks/adminimagecatalogue/src/ImageCatalogue.web"));
const AdminImageCatalogueBulkUpload = lazy(() => import("../../../blocks/adminimagecataloguebulkupload/src/ImageCatalogueBulkUpload.web"));
const AdminEditImage = lazy(() => import("../../../blocks/admineditimage/src/EditImage.web"));
const AdminCustomerFeedback = lazy(() => import("../../../blocks/admincustomerfeedback/src/CustomerFeedback.web"));
const AdminCustomerFeedbackCreate = lazy(() => import("../../../blocks/admincustomerfeedback/src/CustomerFeedbackCreate.web"));
const AdminStoreDetails = lazy(() => import("../../../blocks/adminstoredetails/src/StoreDetails.web"));
const AdminTaxes = lazy(() => import("../../../blocks/admintaxes/src/Taxes.web"));
const AdminShipping = lazy(() => import("../../../blocks/adminshipping/src/Shipping.web"));
const AdminPromoCode = lazy(() => import("../../../blocks/adminpromocode/src/PromoCode.web"));
const AdminPromoCodeCreate = lazy(() => import("../../../blocks/adminpromocode/src/PromoCodeCreate.web"));
const AdminPaymentIntegration = lazy(() => import("../../../blocks/adminpaymentintegration/src/Payment.web"));
const AdminMobileApp = lazy(() => import("../../../blocks/adminmobileapp/src/MobileApp.web"));
const AdminNotifications = lazy(() => import("../../../blocks/adminnotifications/src/Notifications.web"));
const AdminOtherIntegrations = lazy(() => import("../../../blocks/adminotherintegrations/src/OtherIntegrations.web"));
const AdminAccount = lazy(() => import("../../../blocks/adminaccount/src/AccountDetails.web"));
const AdminProducts = lazy(() => import("../../AdminProducts/src/Products.web"));
const AdminAddProduct = lazy(() => import("../../AdminProducts/src/components/AddProduct/AddProduct"));
const AdminCategories = lazy(() => import("../../AdminCategories/src/categories/Categories"));
const AdminAddCategory = lazy(() => import("../../AdminCategories/src/addCategory/AddCategory"));
const AdminBrands = lazy(() => import("../../AdminBrands/src/Brands.web"));
const AdminCustomers = lazy(() => import("../../AdminCustomers/src/CustomersInboundQueries.web"));
const AdminVariants = lazy(() => import("../../AdminVariants/src/Variants.web"));
const AdminDashboard = lazy(() => import("../../AdminDashboard/src/Dashboard.web"));
const AdminOrders = lazy(() => import("../../AdminOrders/src/Orders.web"));
const AdminOrderDetails = lazy(() => import("../../AdminOrders/src/OrderDetails.web"));
const Branding = lazy(() => import('../../AdminWebsiteBuilder/src/Branding.web'));
const Adminconsole2 =lazy(() => import('../../adminconsole2/src/Adminconsole2.web').then(module => ({default: module.NewAdminconsole2})));
const EditCustomer  = lazy(() => import('../../adminconsole2/src/EditCustomer').then(module => ({default: module.NewEditCustomer})));

const skeletonLoader = (
  <div className="SkeletonLoader-Admin container">
    <Skeleton variant="rect" width="100%" height="68px" />
    <div className="SkeletonLoader-Admin sideBar-Content-container">
      <Skeleton variant="rect" className="SkeletonLoader-Admin sideBar" />
      <div className="SkeletonLoader-Admin Content">
        <div className="SkeletonLoader-Admin Content-Header">
          <Skeleton variant="rect" width="48%" height="50px" />
          <Skeleton variant="rect" width="48%" height="50px" />
        </div>
        <Skeleton variant="rect" width="100%" height="50px" />
        <Skeleton variant="rect" width="100%" height="calc(100vh - 320px)" />
        <div className="SkeletonLoader-Admin Content-Footer">
          <Skeleton variant="rect" width="100px" height="50px" />
        </div>
      </div>
    </div>
  </div>
);

// Customizable Area End
class WebRoutes extends Component {
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    window.history.scrollRestoration = 'manual';
    window.scrollTo(0, 0);
  }
  isAdminRoute = window.location.href.includes('admin');

// Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <BrowserRouter>
        <Suspense fallback={skeletonLoader}>
          <Switch>
            {/* Web Routes */}
            {!this.isAdminRoute ? <>
            <Route path="/" exact render={(props: any) => <LandingPage {...props} />} />
            <Route path="/catalogue" exact render={(props: any) => <Catalogue {...props} />} />
            <Route path="/contact-us" exact render={(props: any) => <ContactUs {...props} />} />
            <Route exact path="/shopping-cart" component={ShoppingCart} />
            <Route exact path="/payment/:id" component={PaymentSuccessScreen} />
            <Route exact path='/payment-failure' component={PaymentFailure} />
            <Route exact path="/payment_loading" component={Tappaychargeidloader} />
            </>
            :
            <>
            <Route exact path="/admin/" component={AdminDashboard} />
            <Route exact path="/admin/EmailAccountLogin" component={EmailAccountLogin} />
            <Route
              exact
              path="/admin/EmailAccountForgotPassword"
              component={EmailAccountForgotPassword}
            />
            <Route
              exact
              path="/admin/AdminEmailTemplates"
              component={AdminEmailTemplates}
            />
            <Route
              //exact
              path="/admin/AdminEmailTemplatesCreate/:id"
              component={AdminEmailTemplatesCreate}
            />
            <Route exact path="/admin/AdminStaticPage" component={AdminStaticPage} />
            <Route exact path="/admin/Branding" component={Branding} />
            <Route
              exact
              path="/admin/AdminStaticPageCreate/:id?"
              component={AdminStaticPageCreate}
            />
            <Route
              exact
              path="/admin/AdminImageCatalogue"
              component={AdminImageCatalogue}
            />
            <Route
              exact
              path="/admin/AdminImageCatalogueBulkUpload"
              component={AdminImageCatalogueBulkUpload}
            />
            <Route exact path="/admin/AdminEditImage/:id" component={AdminEditImage} />
            <Route
              exact
              path="/admin/AdminCustomerFeedback"
              component={AdminCustomerFeedback}
            />
            <Route
              exact
              path="/admin/AdminCustomerFeedbackCreate/:id?"
              component={AdminCustomerFeedbackCreate}
            />
            <Route exact path="/admin/AdminStoreDetails" component={AdminStoreDetails} />
            <Route exact path="/admin/AdminTaxes" component={AdminTaxes} />
            <Route exact path="/admin/AdminShipping" component={AdminShipping} />
            <Route exact path="/admin/AdminPromoCode" component={AdminPromoCode} />
            <Route
              exact
              path="/admin/AdminPromoCodeCreate/:id?"
              component={AdminPromoCodeCreate}
            />
            <Route
              exact
              path="/admin/AdminPaymentIntegration"
              component={AdminPaymentIntegration}
            />
            <Route exact path="/admin/AdminMobileApp" component={AdminMobileApp} />
            <Route
              exact
              path="/admin/AdminNotifications"
              component={AdminNotifications}
            />
            <Route
              exact
              path="/admin/AdminOtherIntegrations"
              component={AdminOtherIntegrations}
            />
            <Route exact path="/admin/AdminAccount" component={AdminAccount} />
            <Route exact path="/admin/products" component={AdminProducts} />
            <Route exact path="/admin/products/add-product/:id?" component={AdminAddProduct} />
            <Route exact path="/admin/products/archived" component={AdminProducts} />
            <Route exact path="/admin/categories" component={AdminCategories} />
            <Route exact path="/admin/categories/add-category/:id?" component={AdminAddCategory} />
            <Route exact path="/admin/brands" component={AdminBrands} />
            <Route exact path="/admin/inbound-queries" component={AdminCustomers} />
            <Route exact path="/admin/variants" component={AdminVariants} />
            <Route exact path="/admin/customers" component={Adminconsole2} />
            <Route exact path="/admin/customers/:id" component={EditCustomer} />
            <Route exact path="/admin/orders" component={AdminOrders} />
            <Route exact path="/admin/orders/:id" component={AdminOrderDetails} />
            </>}
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
    // Customizable Area End
  }
}

export default WebRoutes;
